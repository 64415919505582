:root {
  /* Brand Colors */
  --Brand1Color: #95e7ff;
  --Brand2Color: #8fdef4;
  --Brand3Color: #7bbbcd;
  --Brand4Color: #6493a1;
  --Brand5Color: #4b6972;

  /* Dark Background Colors */
  --DarkBack1Color: #121212;
  --DarkBack2Color: #181819;
  --DarkBack3Color: #1e1e1e;
  --DarkBack4Color: #2d2d2d;
}
@keyframes clickAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  flex-direction: column;
}

.App-logo {
  width: 200px;
  pointer-events: none;
}
.form-logo {
  width: 50%;
  pointer-events: none;
  margin-bottom: 10px;
  min-width: 280px;
}

.form-logo-only {
  width: 50%;
  pointer-events: none;
  margin-bottom: 10px;
  min-width: 200px;
  padding-bottom: 50px;
}

.App-body {
  background-color: var(--DarkBack2Color);
  /* Dynamic viewport height solves the 100vh problem on mobile, 100vh is left as a fallback in case the browser does not support dvh. */
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden; /* TODO: Might not be required? */
  overflow-x: hidden; /* Required */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-button {
  width: 50%;
  min-width: 280px !important;
}

.form-button:hover {
  background-color: var(--Brand4Color);
}

.form-input {
  border-radius: 10px;
  font-size: 16px;
  width: 50%;
  min-width: 280px !important;
}

.form-input:focus {
  outline: none;
  border-color: var(--Brand4Color);
}
.form-background {
  background: radial-gradient(var(--DarkBack3Color), var(--DarkBack1Color));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-x: hidden;
  align-items: center;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.list-item {
  background-color: var(--DarkBack4Color);
  border: 1px solid var(--DarkBack3Color);
  border-radius: 10px;
  width: 60%;
  padding: 20px;
  margin: 5px;
}

.scenario-list-item {
  background-color: var(--DarkBack4Color);
  border-bottom: 1px solid var(--DarkBack1Color);
  padding: 20px;
}

.scenario-title {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  color: var(--Brand1Color);
  text-align: left;
}

/* Header */
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 20%;
  height: 100vh;
  background-color: var(--DarkBack1Color);
  z-index: 1;
}

.header > img {
  width: 80%;
  margin-top: 20px;
}

.nav-links {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.nav-link {
  display: flex;
  flex-direction: column;
  color: white;
  text-decoration: none;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
}
.nav-link:active {
  animation: clickAnimation 0.2s ease-in-out;
}

.nav-icon {
  max-height: 24px;
  width: 24px;
  align-self: center;
}

.nav-span {
  padding-top: 5px;
}

.page-container {
  position: relative;
}

.device-list-container {
  justify-content: center;
  align-items: center;
  padding-left: 20%;
  width: 80%;
}

.device-name {
  grid-column: 2 / span 2;
  grid-row: 1 / span 1;
  font-size: large;
  cursor: pointer;
  justify-self: baseline;
}

.group-name {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  font-size: large;
  cursor: pointer;
  justify-self: baseline;
}

.scrollable-content {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}
.scrollable-menu-content {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}
.device-content {
  position: relative;
  width: 100%;
  flex-grow: 1;
  overflow-y: hidden;
}
.device-content-canvas {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 100px);
  overflow-y: hidden;
}
.device-container img {
  max-width: 100%;
  height: calc(100vh -120px);
  grid-column: 2;
  grid-row: 1;
}
.device-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  list-style: none;
  justify-content: center;
}

.menu-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  list-style: none;
  justify-content: center;
  width: 80%;
}

.device-move {
  position: absolute;
  height: 100vh;
  width: 25%;
  padding: 0;
  right: 0;
  background-color: #4b697256;
}

.slider-container {
  grid-column: 2 / span 3;
  grid-row: 2 / span 1;
  width: 100% - 20px;
  margin-top: 10px;
  margin-right: 0px;
}
.MuiSlider-thumb {
  cursor: grab;
  background-image: url("./assets/slider-thumb.svg");
  background-repeat: no-repeat;
  color: transparent;
}
.slider-container-group {
  grid-column: 1 / span 3;
  grid-row: 2 / span 1;
  width: 100% - 20px;
  margin-top: 10px;
  margin-right: 0px;
}

.slider-track {
  width: 100%;
  height: 8px;
  background: var(--Brand1Color);
  border-radius: 5px;
  appearance: none;
  outline: none;
}

.slider-track::-webkit-slider-thumb {
  appearance: none;
  width: 16px;
  height: 16px;
  background: var(--Brand2Color);
  border-radius: 50%;
  cursor: pointer;
}

.slider-track:active::-webkit-slider-thumb {
  background: var(--Brand3Color);
}

.weather-rectangle {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0px;
  width: 100%;
  background: linear-gradient(
    to bottom,
    var(--Brand5Color),
    var(--Brand3Color)
  );
  height: 65px;
}
.weather-text {
  font-weight: 200;
}

.device-header-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--DarkBack1Color);
  height: 65px;
}

.device-header-grid span {
  font-size: medium;
}
.device-header-grid p {
  flex: 1;
}
.device-menu-two-item-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0px;
  width: 100%;
  background: linear-gradient(to bottom, gray, lightgray);
  height: 65px;
  cursor: pointer;
}
.device-menu-two-item-grid div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.device-menu-two-item-grid span {
  font-size: medium;
}
.device-menu-two-item-grid .active {
  background-color: var(--Brand3Color);
  color: white;
  height: 100%;
  width: 100%;
}

.device-menu-grid {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0px;
  width: 100%;
  background: linear-gradient(to bottom, gray, lightgray);
  height: 65px;
  cursor: pointer;
}
.device-menu-grid div:active {
  animation: clickAnimation 0.2s ease-in-out;
}

.device-menu-grid div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  transition: transform 0.5s ease-in-out;
}

.device-menu-grid span {
  font-size: medium;
}
.device-menu-grid .active {
  background-color: var(--Brand3Color);
  color: white;
  height: 100%;
  width: 100%;
}

.weather-rectangle img {
  width: 25%;
  width: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.device-option-rectangle {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: var(--DarkBack3Color);
  height: 65px;
}
.device-option-rectangle img {
  width: 25%;
  width: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.device-option-rectangle2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: gray;
  height: 65px;
}
.device-option-rectangle2 img {
  width: 25%;
  width: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.scenario-list-container {
  justify-content: center;
  align-items: center;
  padding-left: 20%;
  width: 80%;
}
.device-buttons {
  display: flex;
  grid-column: 4 / span 1;
  grid-row: 1 / span 1;
  justify-content: space-between;
  width: 140px;
  justify-self: end;
}
.device-buttons div {
  display: flex;
  justify-content: space-between;
  width: 140px;
}

.group-buttons {
  display: flex;
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  justify-content: space-between;

  justify-self: end;
}
.group-buttons div {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.device-info-container {
  display: grid;
  grid-template-columns: 100px 1fr 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.group-info-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.grid-option {
  display: flex;
  align-items: center;
  justify-content: center;
}

.device-icon {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  justify-self: baseline;
  width: 100%;
  display: grid;
  grid-template-columns: 20px 60px 20px;
  height: 100%;
}

.dialog-content-option {
  margin-top: 10px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 10px;
}

.language-option:hover {
  background-color: var(--Brand4Color);
}
.list-item-background {
  border-radius: 10px;
}

.small-square {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: rgba(18, 18, 18, 0.8);
  color: white;
  border-radius: 10px;
  border-width: medium solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.small-square:active {
  animation: clickAnimation 0.2s ease-in-out;
}

.switch-item-container {
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.forgotten-password {
  font-size: x-small;
  color: var(--Brand5Color);
  margin-bottom: 12px;
  cursor: pointer;
  min-width: 200px !important;
  text-align: center;
}
.sign-in-with-text {
  font-size: small;
  color: var(--Brand3Color);
  margin-top: 12px;
  margin-bottom: 5px;
}

.form-forgotten-password-text {
  width: 50%;
  text-align: center;
  font-size: medium;
  min-width: 280px !important;
}

.sign-in-buttons {
  flex-direction: column;
  align-items: center;
  display: flex;
  gap: 10px;
}
.signin-button {
  width: 180px;
  height: 40px;
}

.active-link {
  color: var(--Brand2Color);
}

.quick-action {
  grid-column: 2 / span 3;
  grid-row: 2 / span 1;
  justify-content: space-evenly;
  width: calc(100% - 20px);
  margin-top: 5px;
  margin-right: 0px;
}

.quick-action-image {
  width: 36px;
  height: 36px;
  border-radius: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}
.quick-action-image:active {
  animation: clickAnimation 0.2s ease-in-out;
}

.quick-action-image img {
  padding: 5px;
}

.door-device-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  margin: 0;
}
.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.circle {
  border-radius: 50%;
  position: absolute;
}
.circle1 {
  width: 250px;
  height: 250px;
  background-color: rgba(20, 20, 20, 0.7);
  z-index: 1;
}
.circle2 {
  width: 220px;
  height: 220px;
  background: linear-gradient(45deg, #cdcdcd, #fefefe);
  z-index: 2;
}
.circle3 {
  width: 210px;
  height: 210px;
  background: linear-gradient(225deg, #cdcdcd, #fefefe);
  z-index: 3;
  cursor: pointer;
}
.lock-image {
  margin: 50px;
}
.circle1-lights {
  width: 240px;
  height: 240px;
  background-color: rgba(20, 20, 20, 0.7);
  z-index: 1;
}
.circle2-lights {
  width: 210px;
  height: 210px;
  background: linear-gradient(45deg, #ccc, #fff);
  z-index: 2;
}
.circle3-lights {
  width: 200px;
  height: 200px;
  background: linear-gradient(225deg, #ccc, #fff);
  z-index: 3;
  cursor: pointer;
}
.circle3:active {
  box-shadow: 0 0 20px var(--Brand3Color);
  transform: scale(1.02);
}

.circle3-lights:active {
  box-shadow: 0 0 20px var(--Brand3Color);
  transform: scale(1.02);
}

.small-button-door-left,
.small-button-door-right {
  position: absolute;
  bottom: 50px;
  transform: translateY(-50%);
  width: 75px;
  height: 75px;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.56);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 4;
}

.small-button-door-left {
  left: calc(50% - 150px);
}

.small-button-door-right {
  right: calc(50% - 150px);
}
.door-device-container {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 100px);
  overflow: hidden;
}
.door-light-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.door-lights-button {
  height: 50vh - 50px;
  align-self: center;
  justify-content: center;
}
.door-light-banner {
  background-color: rgba(0, 0, 0, 0.5);
  width: 50%;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  top: 10vh;
  position: relative;
  z-index: 2;
}

.light-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.light-device-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  margin: 0;
}

.device-type-grid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  width: 64%;
  margin: auto;
}

.device-type-grid div {
  margin-top: 10px;
  margin-bottom: 10px;
}

.device-type-grid .typography-column {
  grid-column: 1;
}

.device-type-grid .button-column {
  grid-column: 3;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.back-icon {
  height: 25px;
  cursor: pointer;
}
.back-icon:active {
  animation: clickAnimation 0.2s ease-in-out;
}

.back-button {
  display: flex;
  position: absolute;
  top: 30px;
  left: 30px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 10px;
}

.back-button:active {
  animation: clickAnimation 0.2s ease-in-out;
}

.settings-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-right: 20px;
  cursor: pointer;
}
.settings-button:active {
  animation: clickAnimation 0.2s ease-in-out;
}

.device-menu-item {
  flex-grow: 1;
  width: 60px;
}
.active-menu-item {
  background-color: var(--Brand3Color);
  height: 100%;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.7;
}

.dialog-content-option {
  cursor: pointer;
  padding: 10px;
}

.selected {
  background-color: rgba(149, 231, 255, 0.2);
}

.invalid-input {
  color: red;
  margin-left: 15px;
  font-size: small;
}
.invalid-alert {
  margin-bottom: 10px;
  font-size: small;
  color: red;
}
.loading-device-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 0;
  top: 0;
}
.loading-device-spinner {
  border: 15px solid #6493a1;
  border-top: 15px solid #95e7ff;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation:
    spin 1.5s linear infinite,
    changeColor2 5s linear infinite;
}

@keyframes changeColor2 {
  0% {
    border-top: 15px solid #95e7ff;
  }
  25% {
    border-top: 15px solid #8fdef4;
  }
  50% {
    border-top: 15px solid #7bbbcd;
  }
  75% {
    border-top: 15px solid #8fdef4;
  }
  100% {
    border-top: 15px solid #95e7ff;
  }
}

.device-offline {
  font-size: 50px;
  color: var(--Brand3Color);
  text-align: center;
  grid-column: 1;
  grid-row: 1;
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: center;
  -webkit-transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: 40px;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
  margin: 0;
  padding: 9px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "Roboto", arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

.move-button-icon {
  height: 25px;
}
